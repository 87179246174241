import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import styled from "styled-components";
import { ConfigProvider, FloatButton, Layout, message } from "antd";

import LoadingPage from "./components/status/LoadingPage/LoadingPage";
import Auth from "./hoc/Auth";

const LoginPage = React.lazy(
  () => import("./components/views/LoginPage/LoginPage"),
);
const Dashboard = React.lazy(
  () => import("./components/views/Dashboard/Dashboard"),
);
const Project = React.lazy(() => import("./components/views/Project/Project"));
const GanttChart = React.lazy(
  () => import("./components/views/GanttChart/GanttChart"),
);
const Members = React.lazy(() => import("./components/views/Members/Members"));
// const MyPage = React.lazy(() => import("./components/views/MyPage/MyPage"));

const NaviMenu = React.lazy(
  () => import("./components/views/NaviMenu/NaviMenu"),
);

const NotFoundPage = React.lazy(
  () => import("./components/status/NotFoundPage/NotFoundPage"),
);

const { Header, Content, Footer, Sider } = Layout;

function App() {
  const location = useLocation();
  const isLoginPage: boolean = Boolean(location.pathname === "/login");

  // const isLoggedIn = useSelector<boolean>(
  //   (state: boolean) => state.auth.isLoggedIn,
  // );

  const AuthLoginPage = Auth(LoginPage, false);
  const AuthDashboard = Auth(Dashboard, true);
  const AuthProject = Auth(Project, true);
  const AuthGanttChart = Auth(GanttChart, true);
  const AuthMembers = Auth(Members, true, true);
  const AuthNaviMenu = Auth(NaviMenu, true);
  const AuthNotFoundPage = Auth(NotFoundPage, false);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#DDDDDD",
          colorPrimaryHover: "#bcbcbc",
          colorPrimaryText: "#414042",
          colorPrimaryTextHover: "#414042",
          fontFamily: "Poppins, sans-serif",
          colorLink: "#414042",
          colorLinkHover: "#bcbcbc",
          colorLinkActive: "#414042",
        },
      }}
    >
      <div>
        <Suspense fallback={<LoadingPage />}>
          {/* <Container> */}
          <Layout hasSider>
            {!isLoginPage && (
              <Sider
                style={{
                  // overflow: "auto",
                  height: "100vh",
                  position: "fixed",
                  left: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <AuthNaviMenu user />
              </Sider>
            )}
            {/* <Content isLoginPage={isLoginPage}> */}
            <Layout
              style={{
                marginLeft: isLoginPage ? 0 : 200,
                backgroundColor: "#f5f5f5",
              }}
            >
              <Content
                style={{
                  margin: isLoginPage ? "0px" : "24px 16px ",
                  overflow: "initial",
                }}
              >
                <div
                  style={{
                    // padding: 24,
                    textAlign: "center",
                  }}
                >
                  <Routes>
                    <Route path="/" element={<AuthDashboard />} />
                    <Route path="/login" element={<AuthLoginPage />} />
                    <Route path="/project" element={<AuthProject />} />
                    <Route path="/chart" element={<AuthGanttChart />} />
                    <Route path="/members" element={<AuthMembers />} />
                    {/* <Route path="/mypage" element={<MyPage />} /> */}
                    <Route path="/*" element={<AuthNotFoundPage />} />
                  </Routes>
                </div>
              </Content>
              <FloatButton.BackTop />
            </Layout>
            {/* </Content> */}
            {/* </Container> */}
          </Layout>
        </Suspense>
      </div>
    </ConfigProvider>
  );
}

const Container = styled.div`
  /* padding-top: 50px; */
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
`;

const Content1 = styled.div<{ isLoginPage: boolean }>`
  margin: ${({ isLoginPage }) => (isLoginPage ? "0px" : "0px 50px")};
  /* min-width: ${({ isLoginPage }) =>
    isLoginPage ? "100vw" : "calc(100vw - 200px)"}; */
  margin-left: 200px;
  width: 100%;
  /* background-color: aqua; */
`;

export default App;
