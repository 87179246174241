import React, { useEffect } from "react";
import { auth } from "../redux/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export default function Auth<P extends {}>(
  SpecificComponent: React.ComponentType<P>,
  option: boolean,
  adminRoute?: boolean,
) {
  function AuthenticationCheck(props: P) {
    const user = useSelector((state: any) => state.auth.user);
    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      dispatch(auth() as any)
        .then((response: any) => {
          // console.log("response:::", response);
          if (!response.payload.success) {
            if (option) {
              // console.log("로그인 안됨.");
              navigate("/login");
              return null;
            }
          } else {
            if (adminRoute && user.pic) {
              console.log("no permission");
              navigate("/");
              // console.log(
              //   "로그인o, 로그인 접근, 관리자x가 관리자페이지 접근"
              // );
              return null;
            } else {
              if (option === false) {
                navigate("/");
                // console.log("로그인o, 로그인 접근");

                return null;
              }
            }
          }
        })
        .catch((err: any) => {
          if (location.pathname !== "/login") {
            // console.log("error!", err);
            navigate("/login");
            return null;
          }
        });
    }, []);

    if (!isLoggedIn && option) {
      navigate("/login");
      return null;
    }

    return <SpecificComponent {...props} user={user} />;
  }

  return AuthenticationCheck;
}
