import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  withCredentials: true,
  headers: {
    // 'Content-Type': 'application/json',
    // "Access-Control-Allow-Origin": true,
    // 'Access-Control-Allow-Origin': 'http://localhost:3000',
    // 'Access-Control-Allow-Origin': 'http://cms.synergium.co.kr',
    // 'Access-Control-Allow-Credentials': true,
    // "Access-Control-Allow-Headers": "Content-Type"
  },
});

export const multipart = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

api.interceptors.request.use(
  (config) => {
    // Get the current timezone offset in minutes
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (config.method === "get") {
      // For other request methods, append timezoneOffset to params
      if (config.params) {
        config.params.timeZone = timeZone;
      } else {
        config.params = { timeZone };
      }
    } else {
      /*
      const formData = new FormData();
      for (let key in config.data) {
        formData.append(key, config.data[key]);
      }
      formData.append("timeZonePost", timeZone);

      console.log("$$$ ", config, "### formData", formData);
      */
      config.data.timeZone = timeZone;
      //config.data = formData;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
export default api;
