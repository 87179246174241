import { createSlice } from "@reduxjs/toolkit";
import api from "../api";
import qs from "qs";
// import moment from "moment";

const initialState = {
  user: {
    key: null,
    email: null,
    firstname: null,
    lastname: null,
    name: null,
    group_key: null,
    role_key: null,
    group: null,
    role: null,
    position: null,

    root: null,
    admin: null,
    pic: null,
  },
  isLoggedIn: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    loginFailure(state, action) {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    logoutUser(state) {
      state.isLoggedIn = false;
      state.user = null;
      // state = initialState;
    },
    authSuccess(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    authFailure(state, action) {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logoutUser,
  authSuccess,
  authFailure,
} = authSlice.actions;
export default authSlice.reducer;

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      // dispatch(loginStart());

      const userData = {
        email: email,
        password: password,
      };

      const response = await api.post("/api/sign/local", qs.parse(userData));

      if (response.data.success) {
        dispatch(
          loginSuccess({
            key: response.data.result.key,
            email: response.data.result.email,
            firstname: response.data.result.firstname,
            lastname: response.data.result.lastname,
            name: response.data.result.name,
            group_key: response.data.result.group_key,
            role_key: response.data.result.role_key,
            group: response.data.result.group,
            position: response.data.result.position,
            role: response.data.result.role,

            root: response.data.result.role_key === 1,
            admin: response.data.result.role_key === 2,
            pic: response.data.result.role_key === 3, // user
          }),
        );
        return {
          payload: response.data,
        };
      } else {
        dispatch(authFailure(response.data.result));
        return {
          payload: response.data,
        };
      }
    } catch (error) {
      dispatch(authFailure(error.message));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    const response = await api.get("/api/sign/out");

    if (response.data.success) {
      return {
        payload: response.data,
      };
    } else {
      console.log("Logout Error", response.data.result);
    }
  } catch (error) {
    return {
      payload: error.response,
    };
  } finally {
    dispatch(logoutUser());
  }
};

export const auth = () => async (dispatch) => {
  try {
    const response = await api.get("/api/sign");

    if (response.data.success) {
      dispatch(
        authSuccess({
          key: response.data.result.key,
          email: response.data.result.email,
          firstname: response.data.result.firstname,
          lastname: response.data.result.lastname,
          name: response.data.result.name,
          group_key: response.data.result.group_key,
          role_key: response.data.result.role_key,
          group: response.data.result.group,
          position: response.data.result.position,
          role: response.data.result.role,

          root: response.data.result.role_key === 1,
          admin: response.data.result.role_key === 2,
          pic: response.data.result.role_key === 3, // user
        }),
      );
      return {
        payload: response.data,
      };
    } else {
      dispatch(authFailure(response.data.result));
      return {
        payload: response.data,
      };
    }
  } catch (error) {
    // console.log("auth err ", error);
    dispatch(authFailure(error.message));
    return {
      payload: error.response,
    };
  }
};
