import React from "react";
import { Spin } from "antd";

type Props = {};

function LoadingPage({}: Props) {
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Spin size="large" style={{ margin: "auto" }} />
    </div>
  );
}

export default LoadingPage;
